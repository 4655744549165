import React, {useState} from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  NumberInput,
  NumberInputField,
  Button,
  Image,
  Heading,
  FormControl,
  FormLabel,
  Spacer,
  Collapse
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"
import { calcPM25, getPM25FromAQI } from './utils'
import Animation from './Animation';
import marlboro from './marlboro.gif';
import './marlborofont.ttf';
import theme from './theme'
import './font.css';
import { useMediaQuery } from '@chakra-ui/react'
import {Footer} from './Footer'

// import Fonts from './Fonts'

import styled from "@emotion/styled";



const MarlboroContainer = styled.div`
  font-family: 'marlboro';
`;



function App() {
  

  const [aqi, setAqi] = useState(250);
  const [hours, setHours] = useState(24);
  const [cigs, setCigs] = useState(-1);
  const [showDetail, setShowDetail] = useState(false);
  const [nycCigs, setNYCCigs] = useState("");

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)', {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  })

  const getPreviousSunday = (): Date => {
    // get current date
    let date: Date = new Date();

    // adjust for Eastern Time
    let utcDate: Date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    let estDate: Date = new Date(utcDate.getTime() - 5 * 60 * 60 * 1000); // Eastern Standard Time is UTC-5

    // set time to 12:01 AM
    estDate.setHours(0, 1, 0, 0);

    // get the current day of the week (0-6, Sunday is 0)
    let dayOfWeek: number = estDate.getDay();

    // calculate number of days to last Sunday
    let daysToLastSunday: number = dayOfWeek === 0 ? 0 : dayOfWeek;

    // subtract the number of days to last Sunday to get last Sunday
    estDate.setDate(estDate.getDate() - daysToLastSunday);

    return estDate;
  }



  function AQI2cigs () {
    const particlesPerCigPerHours = 22
    // var hours = 24

    //   hours = Number(hours)
   
    // Estimated particles for 24 hours
    var pm25fromaqi = getPM25FromAQI(aqi)
    const PM24h = calcPM25(pm25fromaqi)

    // Source: http://berkeleyearth.org/archive/air-pollution-and-cigarette-equivalence/
    const cigs = Number(((PM24h / particlesPerCigPerHours / 24) * hours).toFixed(2))

    setCigs(cigs)
  }

  function AQI2cigsNYC () {
    const particlesPerCigPerHours = 22
    var aqis = [149, 153, 154, 168, 195, 196, 191, 187, 183, 179, 172, 169, 166, 159, 162, 160, 159, 161, 161, 159, 166, 318, 342, 353, 326, 246, 240, 246, 266, 266, 263, 256, 234, 221, 186, 180, 177, 182, 185, 184, 183, 181, 177, 172, 162, 159, 152, 135, 137, 154, 154, 145, 139, 133, 126, 78, 97, 41, 43, 72, 66, 68, 69, 71, 71, 68, 58, 53, 52]
    var inferred_aqi_june_6_before_4pm = [144, 144, 144, 144, 144, 144, 144, 144, 144, 144, 144, 144, 144, 144]
    aqis = aqis.concat(inferred_aqi_june_6_before_4pm)

    var hourlycigs = aqis.map(aqi => calcPM25(getPM25FromAQI(aqi))/ particlesPerCigPerHours / 24 )
    let sum = hourlycigs.reduce((partialSum, a) => partialSum + a, 0);


    const ESTIMATED_NYC_AQI_THIS_WEEK:number = 40
    var per_second_change = calcPM25(getPM25FromAQI(ESTIMATED_NYC_AQI_THIS_WEEK))/ particlesPerCigPerHours / 24 / 3600
    var result = sum;
    
    // Reference time: June 9, 2023 7pm UTC
    // var referenceTime:any = new Date(Date.UTC(2023, 5, 9, 19, 0, 0)); 
    var referenceTime:any = getPreviousSunday();

    // Updating the result every second
    // setInterval(() => {
        let currentTime:any = new Date();
        let diffInSeconds = (currentTime - referenceTime) / 1000;
        // result = sum + per_second_change * diffInSeconds;
        result = per_second_change * diffInSeconds;
        // console.log(Number((result + .07 ).toFixed(6)));
    // }, 1000);
    
    // Initial return value
    setNYCCigs(((result + .07 ).toFixed(6)))
    return Number((result + .07 ).toFixed(6))
}

  setInterval(() => {
    AQI2cigsNYC()
  }, 100);


  function cigString(n: number) {
    let result = '';
    for(let i :number = 0; i < n-1; i++) {
        result += '🚬';
    }
    return result;
  }
  
  return (
  <ChakraProvider theme={theme}>
    {/* <Fonts /> */}
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={0}>
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
        <VStack spacing={6}>
      {isLargerThan800 ?
        <div className="triangle-container">
          <div className="triangle-left"></div>
          <div className="triangle-right"></div>
        </div> : 
        <div className="triangle-container">
          <div className="triangle-left-mobile"></div>
          <div className="triangle-right-mobile"></div>
        </div>
      }
      <Image  marginTop={[-35, -50]} width={[90, 130]}  src={marlboro} alt="loading..." />
        <MarlboroContainer>
        {isLargerThan800 ?  <Text paddingLeft={2} paddingRight={2} marginTop={[-3, -6]} fontSize={['5xl','7xl']}>New Yorkers smoked {nycCigs} cigarettes this week.</Text>
        : <>
          <Text paddingLeft={2} paddingRight={2} marginTop={[-3, -6]} fontSize={['5xl','6xl']}>New Yorkers smoked</Text>
          <Text paddingLeft={2} paddingRight={2} marginTop={[-3, -6]} fontSize={['5xl','6xl']}>{nycCigs} cigarettes</Text>
          <Text paddingLeft={2} paddingRight={2} marginTop={[-3, -6]} fontSize={['5xl','6xl']}>this week.</Text>
          </>
        }
          </MarlboroContainer>
          <Text  opacity={'60%'} fontWeight={'300'} marginTop={-6} fontSize={['md','lg']}>Based on this{' '}
  <Link isExternal={true} href='https://www.iqair.com/us/usa/new-york/new-york-city'>
    week's air quality
  </Link></Text>
          <Text marginTop={[-3, 0]} fontWeight={500} >Calculate cigarettes for your own AQI:</Text>
          <Box marginTop={[-2, 0]} borderWidth={4} borderColor={'black'} borderRadius={"sm"} p={[4,5]}>
          <FormControl>
            <FormLabel fontWeight={730} textColor={'black'}>Air Quality Index (AQI)</FormLabel>
            <NumberInput  defaultValue={250} min={0} max={500} onChange={(valueString) => setAqi(Number(valueString))}>
                <NumberInputField fontWeight={700} textColor={'black'}/>
            </NumberInput>
            <Spacer height= {2}/>
            <FormLabel fontWeight={730} textColor={'black'}>Hours Exposed</FormLabel>
            <NumberInput defaultValue={24} min={0} max={1000} onChange={(valueString) => setHours(Number(valueString))}>
              <NumberInputField fontWeight={700} textColor={'black'}/>
            </NumberInput>
          </FormControl>
          </Box>
          <Button marginTop={'-2'} onClick={() => AQI2cigs()}>
            Calculate
          </Button>
          {cigs === -1 ? null : 
          <>
                    {/* <MarlboroContainer> */}
            <Text marginTop={'-2'}>{cigs} cigarettes</Text>
            {/* </MarlboroContainer> */}

            <Text marginTop={[-8, -5]} fontSize={'4xl'}>{cigString(cigs)}</Text>
          </>
          }
              <Spacer height={20}/>
    {/* <Box cursor={'pointer'} _hover={{opacity: .85}}  onClick={() => setShowDetail(!showDetail)} w={['95vw', '55vw']} p={1} borderWidth={1} borderColor={'gray'} borderRadius={'md'} >
      <Text  fontSize={'sm'}>How is this calculated?</Text>
      <Collapse  in={showDetail} >
        <Text textAlign={'left'} fontSize={'sm'}>TBD</Text>
      </Collapse>
    </Box> */}

        </VStack>
      </Grid>
    </Box>
    <Footer />

  </ChakraProvider>
  );
}

export default App;

