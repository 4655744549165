import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const styles = {
  global: {
    body: {
      transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
    },
  },
};

const theme = extendTheme({ 
    // fonts: {
    //     heading: `Marlboro`,
    //     body: `Marlboro`,
    //   },
    config, styles });

export default theme;
