// pulled from page 11 of https://www.epa.gov/sites/production/files/2014-05/documents/zell-aqi.pdf
const AQITablePM25 = {
  concentrations: [
    {
      min: 0,
      max: 15.5,
      index: {
        min: 0,
        max: 50
      }
    },
    {
      min: 15.5,
      max: 40.5,
      index: {
        min: 50,
        max: 100
      }
    },
    {
      min: 40.5,
      max: 65.5,
      index: {
        min: 100,
        max: 150
      }
    },
    {
      min: 65.5,
      max: 150.5,
      index: {
        min: 150,
        max: 200
      }
    },
    {
      min: 150.5,
      max: 250.5,
      index: {
        min: 200,
        max: 300
      }
    },
    {
      min: 250.5,
      max: 500.5,
      index: {
        min: 300,
        max: 400
      }
    },
    {
      min: 500.5,
      max: 99999999999, // yea I know
      index: {
        min: 400,
        max: 99999999999
      }
    }
  ]
}

/** Get the upper and lower bounds of particle concentration for corresponding AQI */
function getBreakpoints (AQI: number) {
  const breakpoints = AQITablePM25.concentrations.find(function (conc) {
    if (conc.index.min <= AQI && conc.index.max > AQI) {
      return conc
    }
  })
  if (breakpoints === undefined) {
    throw new Error(`AQI out of bounds. AQI: ${AQI}`)
  }
  return breakpoints
}

/** Calculate PM 2.5 concentration per 24 hours based on AQI */
function calcPM25 (AQI: number) {

  const breakpoints = getBreakpoints(AQI)
  // particle concentration
  const PM_min = breakpoints.min
  const PM_max = breakpoints.max
  // air quality index
  const AQI_min = breakpoints.index.min
  const AQI_max = breakpoints.index.max

  // particle concentration based on given AQI
  // this equation is based on the one used here: https://www.airnow.gov/aqi/aqi-calculator/
  const PM = ((AQI - AQI_min) / (AQI_max - AQI_min)) * (PM_max - PM_min) + PM_min

  return PM
}

function getPM25FromAQI(aqi: number) {
    let Clow, Chigh, Ilow, Ihigh;
    
    if(aqi >= 0 && aqi <= 50) {
      Clow = 0.0; Chigh = 12.0;
      Ilow = 0; Ihigh = 50;
    } else if(aqi >= 51 && aqi <= 100) {
      Clow = 12.1; Chigh = 35.4;
      Ilow = 51; Ihigh = 100;
    } else if(aqi >= 101 && aqi <= 150) {
      Clow = 35.5; Chigh = 55.4;
      Ilow = 101; Ihigh = 150;
    } else if(aqi >= 151 && aqi <= 200) {
      Clow = 55.5; Chigh = 150.4;
      Ilow = 151; Ihigh = 200;
    } else if(aqi >= 201 && aqi <= 300) {
      Clow = 150.5; Chigh = 250.4;
      Ilow = 201; Ihigh = 300;
    } else if(aqi >= 301 && aqi <= 500) {
      Clow = 250.5; Chigh = 500.4;
      Ilow = 301; Ihigh = 500;
    } else {
      return -1; // return -1 for AQI values outside the expected range
    }
  
    let C = ((aqi - Ilow) * (Chigh - Clow) / (Ihigh - Ilow)) + Clow;
    return C;
  }
  

export { calcPM25, getPM25FromAQI }